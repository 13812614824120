import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

const SecondPage = () => (
  <Layout>
    <SEO title="About" />
    <h1>About this service</h1>
    <p>
      This service lets you connect to other internet users directly,
      without a vendor / 3rd party and shold provide a stable and fast
      non-laggy service
    </p>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
);

export default SecondPage;
